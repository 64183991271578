import styled from "styled-components"

import { formatDate } from "src/helpers/date"
import { COLORS, LAYOUT } from "src/layout/constants"

const Container = styled.div`
  border: 1px solid ${COLORS.white};
  margin: ${LAYOUT.margin}px 0;
  padding: ${LAYOUT.margin}px;
  max-width: ${LAYOUT.column}px;
`
const Title = styled.h4`
  margin: 0;
`
const Apply = styled.a`
  float: right;
  color: ${COLORS.pink};
`

const getLink = (target: string) => (target.includes("@") ? "mailto:" + target : target)

interface Props {
  job: Job
}

const JobBox = ({ job: { type, title, start, end, description, pdf, target } }: Props) => {
  return (
    <Container>
      <Title>
        {type} • {title}
      </Title>
      <p>
        <strong>Date : </strong>
        {formatDate(start, "MMMM yyyy")} – {formatDate(end, "MMMM yyyy")}
        <br />
        <strong>Description : </strong>
        <em>{description.description}</em>
      </p>
      <Apply href={getLink(target)} target="_blank" rel="noopener">
        postuler
      </Apply>
      {pdf ? <a href={pdf.file.url}>consulter l’offre</a> : <span>&nbsp;</span>}
    </Container>
  )
}

export default JobBox
