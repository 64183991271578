import styled, { css } from "styled-components"

import { COLORS, FONTS, LAYOUT } from "src/layout/constants"

export const Column = styled.section`
  max-width: ${LAYOUT.column}px;
  margin: 0 auto;
  padding: ${LAYOUT.margin}px;
  position: relative;
  @media (max-width: 799px) {
    padding: 0 ${LAYOUT.margin}px;
  }
`
export const Section = styled(Column)`
  max-width: ${LAYOUT.maxWidth}px;
`
export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${LAYOUT.margin * 2}px -${LAYOUT.margin}px;
  @media (max-width: 799px) {
    display: block;
    margin: ${LAYOUT.margin}px -${LAYOUT.margin}px;
  }
`
export const block = css`
  flex: 0 1 calc(50% - ${LAYOUT.margin * 2}px);
  margin: 0 ${LAYOUT.margin}px ${LAYOUT.margin * 2}px;
`

// TODO: use better CSS, see https://css-tricks.com/line-clampin/
export const clamp = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const horizontalScroll = css`
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  > div {
    white-space: initial;
  }
`

export const PageHead = styled.div`
  text-align: center;
  padding: 0 ${LAYOUT.margin}px;
  h1 {
    color: ${({ theme }) => theme.title};
    max-width: 500px;
    margin: 0.6em auto;
  }
  h2 {
    color: ${COLORS.sherpa};
    font-weight: normal;
    font-size: 1.2em;
    max-width: 400px;
    margin: 0 auto;
  }
  svg {
    width: 142px;
  }
  @media (max-width: 799px) {
    font-size: 0.8em;
    svg {
      width: 100px;
    }
    h1 {
      margin-top: 10px;
    }
  }
`

export const Subtitle = styled.h3`
  font-family: ${FONTS.title};
  font-size: 1.8em;
  color: ${COLORS.blue};
`

// agency & methods

export const BgImage = styled.div`
  position: relative;
  color: ${COLORS.lightBlue};
  button {
    width: 100%;
    text-align: left;
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    img {
      object-position: top center !important;
    }
  }
  p {
    padding: 70px ${LAYOUT.margin * 4}px 90px;
    margin: ${LAYOUT.margin * 2}px 0 0;
    font-size: 2.5em;
  }
  @media (max-width: 799px) {
    margin: 0 -${LAYOUT.margin}px;
    p {
      padding: ${LAYOUT.margin * 2}px 50px;
      font-size: 1.6em;
    }
  }
`

// contact & jobs

export const Title = styled.h1`
  color: ${COLORS.pink};
`
export const Paragraph = styled.p`
  font-size: 1.2em;
`
