import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import { Column, Title, Paragraph } from "src/layout/styled"
import { COLORS, FONTS, LAYOUT } from "src/layout/constants"
import JobBox from "src/components/Job"

const Subtitle = styled.h3`
  font-family: ${FONTS.title};
  font-size: 1.5em;
  background-color: ${COLORS.pink};
  display: inline-block;
  padding: 2px 8px;
  margin: 0;
`
const Jobs = styled.div`
  margin: ${LAYOUT.margin * 3}px 0;
`

interface Data {
  jobs: { nodes: Job[] }
}

const JobsPage: GatsbyPage<Data> = ({ data }) => {
  const subtitle = "En forte croissance, l’Agence Phare recherche de nouveaux talents."
  const jobs = data.jobs.nodes

  return (
    <Layout theme="dark" title="Nous rejoindre" description={subtitle}>
      <Column>
        <Title>Nous rejoindre</Title>
        <Paragraph>{subtitle}</Paragraph>
        <Paragraph>
          Que vous soyez débutant·e ou plus expérimenté·e, nous nous intéressons aux profils créatifs et à fort
          potentiel.
        </Paragraph>
        {jobs.length > 0 && (
          <Jobs>
            <Subtitle>Nous cherchons actuellement :</Subtitle>
            {jobs.map((job) => (
              <JobBox key={job.id} job={job} />
            ))}
          </Jobs>
        )}
        <Paragraph>
          Si vous vous reconnaissez dans nos valeurs et la manière dont nous travaillons, nous serons heureux de
          discuter avec vous d’une possible collaboration.
        </Paragraph>
        {!jobs.length && (
          <Jobs>
            <em>Aucune offre en cours</em>
          </Jobs>
        )}
      </Column>
    </Layout>
  )
}

export default JobsPage

export const query = graphql`
  query {
    jobs: allContentfulJob(sort: { fields: start }) {
      nodes {
        id: contentful_id
        title
        type
        start
        end
        description {
          description
        }
        pdf {
          file {
            url
          }
        }
        target
      }
    }
  }
`
